import React, { useEffect, useRef, useState } from "react"
import { graphql, PageProps, Link } from "gatsby"

import { ArrowRight } from "react-feather"
import ScrollIntoView from "react-scroll-into-view"

import Layout from "../components/layout"
import { Button } from "../components/ui"

import ItemMusic from "../components/item-music"
import ItemBlog from "../components/item-blog"
import { Form, Description as ContactDescription } from "../components/contact"
import { IndexPageQuery } from "./__generated__/IndexPageQuery"

export default ({ data, location }: PageProps<IndexPageQuery>) => {
    // config.ts
    const siteData = data.site.siteMetadata

    // contents/music の内容
    const musicList = data.music.edges.map((item, _) => (
        <ItemMusic
            data={item.node}
            key={`p-item-index-${item.node.id}`}
            even={(_ + 1) % 2 === 0}
        />
    ))

    // TODO: 別から動的に取ってきたい
    const newsList = [
        {date: '2021-05-03', title: 'サイト公開しました。'},
        {date: '2021-09-17', title: '1st Album 情報掲載しました。'},
        {date: '2021-10-20', title: 'ゲスト参加情報を更新'},
        {date: '2021-11-27', title: '1st Album 購入情報や試聴動画など更新'},
    ]

    const blogList = data.blog.edges.map(item => (
        <ItemBlog data={item.node} key={`b-item-index-${item.node.id}`} />
    ))

    // メインページのレイアウトここ
    return (
        <Layout
            front={true}
            seo={{
                title: "Home",
                description: siteData.description,
            }}
            navPlaceholder={false}
            location={location}
        >
            <Wall data={siteData} />

            {/* 2022 M3バナー */}
            {/*
            <div className="px-4 py-4 text-center lg:px-0">
                <Link to="/m3-2022a">
                    <img
                        src="/images/2022M3a-banner.png"
                        className="image-center"
                    />
                </Link>
            </div>
            */}

            {/* 2021アルバムバナー */}
            {/*
            <div className="px-4 py-20 text-center lg:px-0">
                <Link to="/catharsis">
                    <img
                        src="/images/catharsis_info.png"
                        className="image-center"
                    />
                </Link>
            </div>
            */}

            {/*
            <News data={newsList}/>
            */}

            <Movie />

            <Blog>{blogList}</Blog>

            {/* ここから */}
            <HeaderMusic />
            <div className="px-4 lg:px-0 pt-0 container mx-auto" id="music">
                {musicList}

                <Link to="/music" title="more" className="text-right mr-4">
                    <p>more →</p>
                </Link>
            </div>
            {/* ここまでMusic */}

            {/*<Contact data={siteData.contact} />*/}
        </Layout>
    )
}

const Wall = ({ data }) => {
    const wall = useRef(null)

    const twoColumnWall = data.twoColumnWall

    const [state, changeState] = useState({
        loaded: false,
        supportsBlend: false,
    })

    useEffect(() => {
        if (window.CSS && !state.loaded) {
            if (CSS.supports("mix-blend-mode", "screen")) {
                wall.current.classList.add("supports-blend")
                changeState({
                    loaded: true,
                    supportsBlend: true,
                })
            }
        }
    }, [state.loaded])

    let spanAttrs: Partial<{ style: unknown }> = {}

    /*
    if (!twoColumnWall && data.titleImage) {
        spanAttrs.style = {
            backgroundImage: `url('${data.titleImage}')`,
        }

    }
    */

    const innerComponents = (
        <React.Fragment>

            {/* タイトル */}
            <div className="title">
                <h1
                    className={`text-6xl relative lg:text-7xl ${
                        data.capitalizeTitleOnHome ? "uppercase" : ""
                    }`}
                >
                    <span {...spanAttrs}></span>
                    <img
                        src="/images/guitarist_mao_official_website.png"
                        className="image-center"
                    />
                    {/*Guitarist<br />mao<br />official website*/}
                    {/*data.title*/}
                </h1>
            </div>

            {/* サブタイトル */}
            <p className="text-center text-2xl lg:text-4xl text-color-2 pt-4 lg:pt-0 pb-6 pl-0 lg:pl-4 subtitle font-pink">
                {data.introTag}
            </p>

            {/*
            <p className="text-base lg:text-lg mt-4">{data.description}</p>
            */}

            {/*
            <ScrollIntoView selector="#music">
                <Button
                    title="SEE WORKS"
                    type="button"
                    iconRight={<ArrowRight />}
                />
            </ScrollIntoView>
            */}
        </React.Fragment>
    )

    if (twoColumnWall) {
        return (
            <div
                className="wall h-screen flex relative justify-center items-center overflow-hidden"
                ref={wall}
            >
                <div className="flex-1 lg:block absolute lg:relative w-full h-full top-0 left-0">
                    <div
                        className="absolute left-0 top-0 w-full h-full lg:hidden"
                        style={{
                            background: "rgba(0,0,0,.35)",
                        }}
                    ></div>
                    {/* にょろちゃんの画像 TODO: bgimageにしたい */}
                    <img
                        src={data.titleImage}
                        alt=""
                        className="w-auto lg:h-auto image-center mt170"
                    />
                </div>
                <div className="flex-1 text-center p-3 relative z-10 lg:text-left lg:pl-8 text-white lg:text-color-default">
                    {innerComponents}
                </div>
            </div>
        )
    }

    return (
        <div
            className="wall h-screen flex flex-col justify-center items-center text-center"
            ref={wall}
        >
            {innerComponents}
        </div>
    )
}

/*
const About = ({ data }) => {
    return (
        <div className="boxed">
            <div className="px-4 py-20 text-center lg:py-40 lg:px-0">
                <h2 className="text-color-1 font-black text-5xl lg:text-6xl">
                    About
                </h2>
                <p className="mt-5 text-lg">{data}</p>
            </div>
        </div>
    )
}
*/

const News = ({data}) => {
    // 引数で受け取った配列からnewslistを作る。path要素があればリンクを貼る。
    const newslist = data.map((d) => {
        let list = {}
        if (d.path) {
            list = <Link to={d.path}><li><span className="mr-5">{d.date}</span>{d.title}</li></Link>
        } else {
            list = <li><span className="mr-5">{d.date}</span>{d.title}</li>
        }
        return list
    })

    return (
        <div className="boxed">
            <div className="px-4 py-20 text-center lg:px-0">
                <h2><img src="/images/h_news.png" alt="News" className="image-center" /></h2>
                <div>
                  <ul>{newslist}</ul>
                </div>
            </div>
        </div>
    )
}

const Movie = () => {
    return (
        <div className="boxed">
            <div className="px-4 py-20 text-center xl:px-0">
                <h2><img src="/images/h_movie.png" alt="Movie" className="image-center" /></h2>
                <div className="grid grid-cols-1 xl:grid-cols-3 xl:gap-2">

                    <div className="image-center my-3">
                        <iframe width="340" height="190" src="https://www.youtube.com/embed/PwM83PJrlTI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>

                    <div className="image-center my-3">
                        <iframe width="340" height="190" src="https://www.youtube.com/embed/jMXgBV93kKE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                    <div className="image-center my-3">
                        <iframe width="340" height="190" src="https://www.youtube.com/embed/NZSynxnGoBM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>
                {/*
                <div className="grid grid-cols-1 xl:grid-cols-3">
                    <div className="image-center my-3">
                        <iframe width="340" height="190" src="https://www.youtube.com/embed/1PIK4HbF8sI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                    <div className="image-center my-3 mx-20">
                        <iframe width="340" height="190" src="https://www.youtube.com/embed/Ico4IS6EMAc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                    <div className="image-center my-3">
                        <iframe width="340" height="190" src="https://www.youtube.com/embed/ScBjIpwVV5I" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>
                */}
            </div>
        </div>
    )
}

const HeaderMusic = () => {
    return (
        <div className="boxed">
            <div className="px-4 pt-24 pb-8 text-center lg:px-0">
                <h2><img src="/images/h_music.png" alt="Music" className="image-center" /></h2>
            </div>
        </div>
    )
}

const Blog = ({ children }) => {
    return (
        <div>
            <div className="boxed">
                <div className="px-4 pt-24 text-center lg:px-0">
                    {/*
                    <h2><img src="/images/h_blog.png" alt="Blog" className="image-center" /></h2>
                    */}
                    <h2><img src="/images/h_news.png" alt="News" className="image-center" /></h2>
                    <p className="mt-5 text-lg"></p>
                </div>
            </div>
            <div className="container mx-auto px-0">
                <div className="flex flex-wrap">{children}</div>
                <Link to="/blog" title="more" className="text-right">
                    <p>more →</p>
                </Link>
            </div>
        </div>
    )
}

const Contact = ({ data }) => {
    const hasContactForm = data.api_url
    return (
        <div className="container mx-auto">
            <div className="pt-20 pb-10 lg:pt-40 lg:pb-20 text-center">
                <h2 className="text-color-1 font-black text-5xl lg:text-6xl">
                    Contact
                </h2>
            </div>
            <div className="flex flex-wrap pb-40">
                {hasContactForm && (
                    <div className="w-full lg:w-1/2 px-4 lg:pl-2 lg:pr-6">
                        <Form api={data.api_url} />
                    </div>
                )}
                <div
                    className={`w-full ${
                        hasContactForm ? "lg:w-1/2" : "lg:w-2/3 mx-auto"
                    } px-6 pt-8`}
                >
                    <ContactDescription data={data} />
                </div>
            </div>
        </div>
    )
}

// description
// about
export const query = graphql`
    query IndexPageQuery {
        site: site {
            siteMetadata {
                title
                introTag
                description
                capitalizeTitleOnHome
                titleImage
                ogImage
                twoColumnWall
                contact {
                    api_url
                    description
                    mail
                    phone
                    address
                }
                social {
                    name
                    url
                    icon
                }
            }
        }
        music: allMdx(
            filter: { fields: { sourceName: { eq: "music" } } }
            limit: 1
            sort: { fields: [frontmatter___date], order: DESC }
        ) {
            edges {
                node {
                    id
                    frontmatter {
                        title
                        description
                        image {
                            childImageSharp {
                                fluid(maxWidth: 1000) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                    fields {
                        slug
                    }
                }
            }
        }
        blog: allMdx(
            filter: { fields: { sourceName: { eq: "blog" } } }
            limit: 3
            sort: { fields: [frontmatter___date], order: DESC }
        ) {
            edges {
                node {
                    id
                    frontmatter {
                        title
                        description
                        date(formatString: "YYYY-MM-DD")
                        image {
                            childImageSharp {
                                fluid(maxWidth: 1000) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                    fields {
                        slug
                    }
                }
            }
        }
    }
`
